/*
* @subsection   RD Navbar Collapse
*
* @description  Describes style declarations for RD Navbar collapse
*/

.rd-navbar-collapse,
.rd-navbar-collapse-toggle{
  display: none;
}

.rd-navbar-static{
  .rd-navbar-inner{
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  .rd-navbar-collapse{
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    li{
      display: inline-block;
      margin-left: 4px;
    }

    a {
      color: $black;
      font-weight: 600;
      padding: .8rem;
      &:hover {
        background: $gray-200;
      }
    }
  }

}

.rd-navbar-fixed{
  .rd-navbar-collapse{
    display: block;
    @extend %rd-navbar-transition;
    text-align: left;
    position: fixed;
    top: 4px;
    right: 4px;
    color: $rd-navbar-contrast-color;
    padding: 48px 0 0;
    font-size: 16px;
    line-height: 26px;
    z-index: 19;
    width: 180px;
    box-shadow: 0 0 0 0 nth($rd-navbar-shadow-medium, 5);
    visibility: hidden;
    overflow: hidden;
    transition: .3s box-shadow ease .2s;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      background: darken($rd-navbar-white-color, 3%);
      border-radius: 50%;
      width: 48px;
      height: 48px;
      @include transform(scale(0));
      @extend %rd-navbar-transition;
    }

    li{
      position: relative;
      opacity: 0;
      transition: .3s all ease 0s;
      border-top: 1px solid #ececec;
    }


    > li > a{
      display: block;
      padding: 11px 16px;

      &:hover{
        background: lighten($rd-navbar-contrast-color, 75%);
      }
    }

    &.active{
      visibility: visible;
      box-shadow: $rd-navbar-shadow-medium;

      li{
        transition: .3s all ease .2s;
        opacity: 1;
      }

      &:before{
        background: $rd-navbar-white-color;
        @include transform(scale(12));
      }
    }
  }

  .rd-navbar-collapse-toggle{
    display: block;
    position: absolute;
    top: 20px;
    right: 16px;
    font-family: 'Material Icons';
    z-index: 20;
    background: transparent;
    border: none;
    font-size: 1.5em;
    @include toggle-icons-via-rotation(48px, 28px, $rd-navbar-contrast-color, '\e5d4', '\e5cd');
  }
}
