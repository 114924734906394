/*
* @subsection   RD Navbar Toggles
*
* @description  Describes style declarations for RD Navbar navigation
*/

.rd-navbar-nav, .rd-navbar-toggle{
  display: none;
}

.rd-navbar-static{

  ul {
    list-style-type: none;
  }

  .rd-navbar-subpanel{
    display: table;
  }

  .rd-navbar-outer .rd-navbar-inner{
    position: relative;
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu{
    @extend %rd-navbar-transition;
    position: absolute;
    padding: 8px 0;
    background: $gray-100;
    text-align: left;
    opacity: 0;
    visibility: hidden;
    border: 1px solid $gray-200;

    li > a{
      display: block;
      line-height: 24px;
      padding: 6px 16px;
      color: $gray-700;
      @include ellipsis;
    }

    li > span {
      color: $gray-800;
      padding: 6px 16px;
      font-weight: 600;
      display: block;
      border-bottom: 1px solid $gray-200;
      &:last-of-type{
        margin-top: 8px;
      }
    }

    li.focus > a, li.opened > a, a:hover{
      color: $rd-navbar-white-color;
      background: $rd-navbar-contrast-color;
    }
  }

  .rd-navbar-dropdown{
    width: 270px;
  }

  .rd-navbar-megamenu{
    left: 0;
    right: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 12px 10px 34px;

    > li{
      display: table-cell;
    }
  }

  .rd-navbar-nav{
    display: block;
    text-align: left;
    margin-bottom: 0;
    padding-left: 0;
    li.rd-navbar--has-dropdown{
      position: relative;
    }

    li.focus,
    li.opened{
      > .rd-navbar-dropdown, > .rd-navbar-megamenu{
        opacity: 1;
        visibility: visible;
        @include transform(translateY(0));
      }
    }

    > li{
      display: inline-block;

      > .rd-navbar-dropdown, > .rd-navbar-megamenu{
        top: 100%;
        z-index: 999;
      }

      > .rd-navbar-dropdown{
        left: 0;
        margin-top: 0;
        margin-left: 0;

        .rd-navbar-dropdown{
          left: 100%;
          top: -8px;
          z-index: 2;
        }
      }

      > a {
        display: block;
        font-weight: 600;
        line-height: 26px;
        padding: 14px;
        color: $gray-100;
      }

      &.active > a, &.opened > a,  &.focus > a, > a:hover{
        background: darken($main-color, 2%);
      }
    }
  }
}

.rd-navbar-fixed {
  .equalizer {
    display: none;
  }
  .rd-navbar-toggle {
    display: block;
    @include toggle-lines-to-arrow(48px, 24px, $rd-navbar-contrast-color);
    background: transparent;
    border: none;
  }

  .rd-navbar-nav-wrap {
    @extend %rd-navbar-transition;
    position: fixed;
    top: -56px;
    left: 0;
    width: 260px;
    padding: 130px 0 56px;
    bottom: -56px;
    color: $rd-navbar-default-color;
    background: $rd-navbar-white-color;
    z-index: 15;
    @include transform(translateX(-105%));

    &.active {
      @include transform(translateX(0));
    }
  }

  .rd-navbar-nav {
    display: block;
    height: 100%;
    overflow: auto;
    line-height: 26px;
    text-align: left;
    padding-left: 0;

    &:before, &:after {
      content: '';
      display: block;
      height: 8px;
    }

    li {
      > a {
        display: block;
        padding: 8px 14px 8px 14px;
        color: $gray-700;
      }

      &.opened > a {
        background: lighten($rd-navbar-contrast-color, 75%);
      }

      &.active {
        .rd-navbar-submenu-toggle{
          color: $rd-navbar-white-color;
        }

        > a {
          color: $rd-navbar-white-color;
          background: $main-color;
        }
      }
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    display: none;
  }

  .rd-navbar-submenu {
    position: relative;
    @extend %rd-navbar-transition;

    li > a {
      padding-left: 22px;
    }

    .rd-navbar-dropdown li li > a,
    .rd-navbar-megamenu ul li li > a {
      padding-left: 48px;
    }

    .rd-navbar-dropdown {
      span {
        padding-left: 1.4rem;
        border-bottom: 1px solid $gray-300;
        display: block;
      }
    }

    &.opened {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        display: block;
        padding-left: 0;
        background: #fff;
      }
      .rd-navbar-open-right ul {
        list-style-type: none;
        padding-left: 1rem;
        li {
          a {
            padding-left: 4px;
            @include ellipsis;
          }
        span {
          border-bottom: 1px solid $gray-300;
          display: block;
          padding-top: .5rem;
        }
        }
      }
    }
  }

  .rd-navbar-submenu-toggle {
    @extend %rd-navbar-transition;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font: 400 24px/24px 'FontAwesome';
    width: 50px;
    height: 48px;
    text-align: center;
    color: $rd-navbar-default-color;
    cursor: pointer;

    &:hover {
      color: $rd-navbar-contrast-color;
    }

    &:before {
      content: '\f0d7';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      transform: translate(0, -50%);
      font-size: 14px;
      color: $gray-700;
    }
  }
}
