h1,.h1-responsive {
    font-size: 150%;
}

h2,.h2-responsive {
    font-size: 140%;
    margin-top: 2rem;
}

h3,.h3-responsive {
    font-size: 130%;
    margin-top: 1.8rem;
}

h4,.h4-responsive {
    font-size: 120%;
    margin-top: 1.6rem;
}

h5,.h5-responsive {
    font-size: 110%;
    margin-top: 1.4rem;
}

.h1-large {
    font-size: 200%;
}

.text-right {
  text-align: right;
}

@media(max-width: 440px) {
  h1,.h1-responsive {
      font-size: 140%
  }

  h2,.h2-responsive {
      font-size: 130%
  }

  h3,.h3-responsive {
      font-size: 120%
  }

  h4,.h4-responsive {
      font-size: 115%
  }

  h5,.h5-responsive {
      font-size: 105%
  }
}

.page-heading {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: .5rem;
  margin-bottom: 24px;
}
