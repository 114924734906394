/* bootstrap core */
@import "../../../node_modules/bootstrap/scss/bootstrap-reboot";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

$grid-breakpoints: (
  xxs: 0,
  xs: 768px,
  sm: 1024px,
  md: 1280px,
  lg: 1366px,
  xl: 1920px
);

$container-max-widths: (
  xs: 800px,
  sm: 960px,
  md: 1260px,
  lg: 1340px,
  xl: 1300px
);

$jumbotron-padding: 1.25rem;
$jumbotron-bg: $gray-100;
$breadcrumb-divider: quote(">");

/* bootstrap components */
@import "../../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/jumbotron";
@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/custom-forms";
@import "../../../node_modules/bootstrap/scss/pagination";

/* font awesome icons */
$fa-font-path: "../../../node_modules/font-awesome/fonts";
@import "../../../node_modules/font-awesome/scss/variables";
@import "../../../node_modules/font-awesome/scss/path";
@import "../../../node_modules/font-awesome/scss/core";
@import "../../../node_modules/font-awesome/scss/icons";


$information-very-small: "only screen and (max-width : 640px)";
$information-small: "only screen and (max-width : 800px)";
$information-tablet: "only screen and (max-width : 1024px)";

/* custom configurations */
$main-color: #19a59f;
$secondary-color: #ffd504;

@import "mixins";
@import "elements";
@import "headings";
@import "rd-navbar/rd-navbar";

/*swiper*/
@import "../../../node_modules/swiper/components/pagination/pagination";
@import "../../../node_modules/swiper/components/navigation/navigation";
@import "../../../node_modules/swiper/components/lazy/lazy.scss";
@import "../../../node_modules/swiper/swiper";
@import "main";
@import "responsive";

.swiper-container {
  z-index: 0;
}

:root {
  --swiper-theme-color: #19a59f;
}
